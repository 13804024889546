.above-nav-border-section {
	height: 2rem;
	background-color: transparent;
	/* box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); */
	/* background-image: var(--navbackgroundimage); */
	/* background-image: var(--navbackgroundimage); */
	/* background-position: center center; */
}

.below-nav-border-section {
	height: 4rem;
	/* background-image: var(--navbackgroundimage); */
	background-position: center center;
}

nav {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 4rem;
	width: auto;
	z-index: 99;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	/* border-top: var(--navborder); */
	/* border-bottom: var(--navborder); */
	background-color: var(--navbackgroundcolor);
}

nav ul {
	margin-bottom: 0;
}

.in-nav-logo {
	display: flex;
	margin-left: 4rem;
	color: black;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 1px;
	left: 0;
}

.heading {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding: 2rem;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.heading h1 {
	font-size: 3rem;
}

.social-icons {
	margin-top: 20px;
}

.social-icons img {
	height: 1.6rem;
	margin: 10px;
}

.nav-links {
	display: flex;
	justify-content: space-around;
	width: 70%;
}

.nav-links li {
	list-style: none;
	transition: all 0.2s ease-in-out;
}

.nav-links a {
	color: var(--navtextcolor);
	font-size: larger;
	letter-spacing: 2px;
	font-weight: normal;
	text-decoration-thickness: 6px;
}

.nav-link:active {
	/* text-underline-offset: 6px; */
	/* text-decoration-thickness: 6px; */
	/* text-decoration: underline; */
}

.nav-links li:hover {
	color: rgb(163, 163, 163);
	cursor: pointer;
}

.burger {
	display: none;
}

.burger div {
	width: 29px;
	height: 3px;
	background-color: var(--navBurgerColor);
	margin: 5px;
	transition: all 0.3s ease;
}

.nav-item {
	display: grid;
	/* justify-content: center; */
}

/* nav .btn-logout {
	width: auto;
	height: 38px;
	background-color: var(--buttonloginlargescreencolor);
	color: var(--blackOrWhite1);
	align-self: center;
} */

nav .btn-login {
	width: auto;
	height: 38px;
	/* background-color: var(--buttonloginlargescreencolor);
	color: var(--blackOrWhite2); */
	align-self: center;
}

.navbar-indicator-circle {
	position: relative;
	left: -15px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	/* background-color: var(--highlight1); */
	background-image: radial-gradient(circle at center, yellow, orange, red);
}

.navbar-indicator-triangle {
	/* display: flex; */
	justify-self: center;
	align-self: top;
	/* position: center; */
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid var(--highlight1);
}

/* New Code */

.in-nav-logo {
	font-size: 1.2rem;
}

.nav-links {
	display: flex;
	/* border: 2px solid black; */
	background-color: white;
}

.nav-item {
	list-style: none;
	position: relative;
}

.nav-link {
	color: var(--navtextcolor);
	font-size: larger;
	letter-spacing: 2px;
	font-weight: normal;
}

.dropdown {
	position: relative;
}

.dropdown-content {
	display: none;
	position: absolute;
	top: calc(100% + 5px);
	left: 0;
	min-width: 160px;
	background-color: var(--navbackgroundcolor);
	border-left: var(--navborder);
	border-right: var(--navborder);
	border-bottom: var(--navborder);

	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
	padding: 0;
	margin: 0;
}

/* .dropdown-content .nav-item {
	padding: 12px 16px;
	cursor: pointer;
} */

/* .dropdown-content .nav-item:hover {
	background-color: #f1f1f1;
} */

.dropdown:hover .dropdown-content {
	display: block;
}

/* New Code */

/* #########################################################  Media Queries #########################################################*/
/* ##################################################################################################################################*/
@media screen and (max-width: 992px) {
	body {
		overflow-x: hidden;
		cursor: pointer;
	}

	nav {
		position: fixed;
		justify-content: space-between;
		height: 3.5rem;
		border-top: none;
		width: 100%;
		margin-right: 0;
		margin-top: 0rem;
	}

	.below-nav-border-section {
		height: 0rem;
	}

	.in-nav-logo {
		margin-left: 2rem;
		/* display: block; */
		/* color: black; */
	}

	.nav-links {
		position: absolute;
		display: flex;
		justify-content: space-evenly;
		right: 0px;
		height: 84vh;
		top: 3rem;
		background-color: var(--lightblack);
		color: var(--blackOrWhite2);
		flex-direction: column;
		align-items: center;
		width: 60%;
		transform: translateX(100%);
		transition: transform 0.5s ease-in;
		z-index: 2;
	}

	.nav-links li {
		opacity: 50;
	}

	.burger {
		display: block;
		margin-right: 1.7rem;
	}

	.line1 {
		background-color: black;
	}

	.line2 {
		background-color: black;
	}

	.line3 {
		background-color: black;
	}
}

.nav-active {
	transform: translateX(0%);
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		transform: translateX(50px);
	}

	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.toggle .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
	background-color: black;
	color: black;
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}
