:root {
	/* --amplify-white: rgb(255, 255, 255);
	--amplify-primary-color: rgb(243, 9, 9);
	--amplify-secondary-color: black;
	--amplify-primary-contrast: rgb(255, 255, 255);
	--amplify-grey: black;
	--amplify-secondary-contrast: white; */

	/* --amplify-fonts-default-variable: "Open Sans";
	--amplify-fonts-default-static: "Open Sans";
	--amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
	--amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
	--amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
	--amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
	--amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
	--amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
	--amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
	--amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
	--amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
	--amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
	--amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
	--amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
	--amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
	--amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
	--amplify-colors-radii-small: 0;
	--amplify-colors-radii-medium: 2px;
	--amplify-colors-radii-large: 4px;
	--amplify-colors-border-primary: var(--amplify-colors-neutral-40);
	--amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
	--amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
	--amplify-colors-background-secondary: var(
		--amplify-colors-brand-primary-10
	);
	--amplify-components-tabs-item-border-color: var(
		--amplify-colors-neutral-60
	);

	--amplify-radii-small: 0;
	--amplify-radii-medium: 0;
	--amplify-radii-large: 0;
	--amplify-space-small: 1rem;
	--amplify-space-medium: 1.5rem;
	--amplify-space-large: 2rem;
	--amplify-border-widths-small: 2px;
	--amplify-border-widths-medium: 4px;
	--amplify-border-widths-large: 8px; */

	/* --highlight1: rgb(51, 255, 0); */
	--highlight1: rgb(220, 220, 220);

	/* --highlight2: rgb(236, 0, 253); */
	--highlight2: rgb(66, 66, 66);

	/* Intended to be a simple way to easily switch between black and white */
	--blackOrWhite1: black;
	--blackOrWhite2: white;

	--lightblack: rgb(149, 149, 149);

	--commonbuttonwidth: 200px;
	--commmonbuttonheight: 65px;

	--commonHorizontalMargin: 2vw;
	--commonVerticalMargin: 2vh;

	--lowerQuadrantAlignmentMargin: 4rem;

	/* button styling */
	--buttoncommoncolor: rgb(246, 246, 246);
	--buttonloginlargescreencolor: whitesmoke;

	/* Navbar styling */
	--navbackgroundcolor: white;
	--navbackgroundimage: url("../../images/mexican-patterns-5-2.jpg");
	--navbackgroundimage1: url("../../images/mexican-patterns-5-2.jpg");
	--navbackgroundimage2: url("../../images/mexican-patterns-5-2.jpg");

	--navtextcolor: black;
	--navborder: 1px solid black;
	--navBurgerColor: black;

	/* Add Words styling */
	--translatesectionbackgroundcolor: white;
	--translatesectionborder: 2px solid rgb(216, 216, 216);
	/* --translatesectionborder: 2px inset #000000; */

	--translatesectionlegendcolor: rgb(62, 62, 62);
	--translatesectionlegendtextsize: 1.2rem;

	/* Test Yourself styling */

	/*  */
}

amplify-sign-in {
	background: black;
}

amplify-container {
	/* height: auto !important; */
}

.amplify-form-container {
	/* max-height: 600px; */
	/* overflow-y: auto; */
}

amplify-sign-up {
	max-height: 600px; /* Adjust this value to set the desired height */
	/* overflow-y: auto; */
}

html {
	height: 100%;
	/* min-height: 100%; */
}

.App {
	display: flex;
	flex-direction: column;
	/* justify-items: center; */
	/* flex: 0 1 0; */
	height: 100%;
	width: 100%;
}

body {
	height: 100%;
	width: 100%;
	background-color: white;
	background-image: url("../../images/background.png");
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

a {
	/* color: #0d6efd; */
	text-decoration: none;
}

@media screen and (max-width: 992px) {
	:root {
		/* --navbackgroundcolor: black; */
		/* --navBurgerColor: white; */

		/* --commonbuttonwidth: 30vw; */
		/* --commmonbuttonheight: 4em; */

		/* --commonHorizontalMargin: 1em; */
		/* --commonVerticalMargin: 1em; */

		/* --lowerQuadrantAlignmentMargin: 4em; */
	}

	.btn {
		width: 30vw;
	}
}

@media screen and (max-width: 768px) {
	:root {
		/* --commonbuttonwidth: 35vw; */
		/* --commmonbuttonheight: 4em; */

		/* --commonHorizontalMargin: 1em; */
		/* --commonVerticalMargin: 1em; */

		/* --lowerQuadrantAlignmentMargin: 4em; */
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Footer {
	flex-shrink: 0;
}

/* @media screen and (max-width: 768px) {
    .App {
        flex-direction: row;
      }
} */
