.footer {
	display: flex;
	background-color: white;
	color: black;
	height: 2.5em;
	align-items: center;
	justify-content: center;
	padding: 0 0 0 0.6rem;
	flex-shrink: 0;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}
