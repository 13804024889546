.translate-section {
	position: relative;
	flex: 1;
	display: grid;
	height: auto;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"form-left form-right"
		"button-section button-section";
}

.form-left {
	/* grid-area: s1; */
	grid-area: form-left;
	display: flex;
	margin-top: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: var(--commonHorizontalMargin);
	padding-right: calc(var(--commonHorizontalMargin) / 2);
	border-top: var(--translatesectionborder);
	border-bottom: var(--translatesectionborder);
	/* box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); */
	/* box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2),
		0 5px 5px -5px rgba(0, 0, 0, 0.2); */

	background-color: var(--translatesectionbackgroundcolor);
}

.form-right {
	/* grid-area: s2; */
	grid-area: form-right;
	display: flex;
	margin-top: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: calc(var(--commonHorizontalMargin) / 2);
	padding-right: calc(var(--commonHorizontalMargin));
	border-top: var(--translatesectionborder);
	border-bottom: var(--translatesectionborder);
	/* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
	/* box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.2),
		0 5px 5px -5px rgba(0, 0, 0, 0.2); */

	background-color: var(--translatesectionbackgroundcolor);
}

.translate-section .button-section {
	grid-area: button-section;
	margin-top: var(--lowerQuadrantAlignmentMargin);
	display: flex;
	justify-content: center;
}

.form-select {
	height: auto;
	flex-grow: 1;
}

.translate-section fieldset {
	display: flex;
	flex-grow: 1;
	background-color: transparent;
	height: auto;
}

.translate-section fieldset legend {
	font-size: var(--translatesectionlegendtextsize);
	color: var(--translatesectionlegendcolor);
	/* text-decoration: underline; */
}

/* #########################################################  Media Queries #########################################################*/

@media screen and (max-width: 992px) {
	.translate-section {
		top: 3rem;
		margin-top: 0;
		grid-template-columns: 1fr;
		grid-template-rows: 3fr 1fr 3fr;
		grid-template-areas:
			"form-left"
			"button-section"
			"form-right";
	}

	.form-left {
		margin-top: 0;
		padding-right: var(--commonHorizontalMargin);
	}

	.form-right {
		margin-top: 0;
		padding-left: var(--commonHorizontalMargin);
	}

	.translate-section .button-section {
		align-items: center;
		margin: 0;
		padding: 0;
	}
}

/* XL screen size: min-width: 1201px */
@media (min-width: 1201px) {
}

/* LG screen size: min-width: 993px and max-width: 1200px */
@media (min-width: 993px) and (max-width: 1200px) {
}

/* MD screen size: min-width: 769px and max-width: 992px */
/* @media (min-width: 769px) and (max-width: 992px) {

} */
