.list-of-user-phrases-section {
	flex-grow: 1;
	position: relative;
	display: flex;
	margin: 0vh 3vw 0vh 3vw;
	flex-direction: column;
	justify-content: space-evenly;
}

.list-of-words-user-phrases .btn {
	margin: 2px;
	background-color: var(--highlight2);
	border: 1px solid black;
	color: white;
}

.list-of-words-user-phrases .table {
	margin-bottom: 0rem;
}

th {
	width: 25vw;
	text-align: center;
	text-decoration: none;
	/* padding-bottom: 0.5em; */
	/* color: black !important; */
}

td {
	text-align: center;
	vertical-align: middle;
}

tr {
	background-color: white;
	border-bottom: 1px solid var(--lightblack);
	border-top: 1px solid var(--lightblack);
	margin: 4px 0px 4px 0px;
}

/* tr:nth-child(odd) {
	background-color: white;
	border-bottom: 1px solid black;
	border-top: 1px solid black;
} */
/* tr:nth-child(even) {
	background-color: white;
	border-bottom: 1px solid black;
} */

.list-of-user-phrases-section .btn-delete {
	width: auto;
	height: auto;
	margin: 3px;
	/* background-color: var(--highlight2); */
}

.table > :not(caption) > * > * {
	padding: 0rem 0rem;
	background-color: var(--bs-table-bg);
	background-image: linear-gradient(
		var(--bs-table-accent-bg),
		var(--bs-table-accent-bg)
	);
	/* border-bottom-width: 1px; */
}

legend {
	float: none;
	background-color: transparent;
	color: white;
	font-size: 2em;
	width: fit-content;
	padding: 3px 6px;
}

@media screen and (max-width: 992px) {
	.list-of-user-phrases-section {
		top: 4rem;
	}
}
