.test-yourself-section .test-yourself-section__left {
	grid-area: s1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--black);
}
.test-yourself-section .test-yourself-section__right {
	grid-area: s2;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--black);
}
