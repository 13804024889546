.pagination {
	display: inline-flex;
	background-color: transparent;
	border-radius: 0.5em;
	justify-content: center;
}

.pagination:hover {
	cursor: pointer;
}

.page-link {
	position: relative;
	display: block;
	color: black;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #dee2e6;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link:hover {
	z-index: 2;
	color: white;
	background-color: var(--highlight2);
	border-color: #dee2e6;
}

.page-item .page-link:active {
	background-color: aqua;
	/* color: aqua; */
}
