.test-yourself-section {
	position: relative;
	flex-grow: 1;
	flex: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"s1 s2"
		"s3 s3";
}

.test-yourself-section .controls-section {
	grid-area: s3;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.test-yourself-section .controls-section .button-section {
	margin-top: var(--lowerQuadrantAlignmentMargin);
	margin-bottom: var(--commonVerticalMargin);
}

@media (min-width: 769px) and (max-width: 992px) {
	.test-yourself-section .test-yourself-section__left h3 {
		font-size: 1.4rem;
		margin: 0 0rem 0 1rem;
	}
	.test-yourself-section .test-yourself-section__right h3 {
		font-size: 1.4rem;
		margin: 0 1rem 0 0rem;
	}

	/* .test-yourself-section .controls-section .btn {
		width: 30vw;
	} */
}

@media (max-width: 768px) {
	.test-yourself-section .test-yourself-section__left h3 {
		font-size: 1.2rem;
		margin: 0 0rem 0 1rem;
	}
	.test-yourself-section .test-yourself-section__right h3 {
		font-size: 1.2rem;
		margin: 0 1rem 0 0rem;
	}

	.test-yourself-section .controls-section .btn {
		width: 40vw;
	}
}

/* Media queries */
/* XS screen size: max-width: 576px */
/* @media (max-width: 576px) {
	.test-yourself-section .controls-section .btn {
		width: 40vw;
	}
} */
