.btn {
	width: var(--commonbuttonwidth);
	height: var(--commmonbuttonheight);
	margin-left: calc(var(--commonHorizontalMargin) / 2);
	margin-right: calc(var(--commonHorizontalMargin) / 2);
	border: 1px solid black;
	background-color: var(--buttoncommoncolor);
	color: rgb(62, 62, 62);
	box-shadow: 0 0 8px rgba(115, 115, 115, 0.5);
	border: 0.5px solid rgb(200, 200, 200);
	font-size: 16px;
	text-transform: uppercase;
}

.btn-logout {
	width: auto;
	height: 38px;
	background-color: var(--buttoncommoncolor);
	color: rgb(62, 62, 62);
	align-self: center;
	box-shadow: 0 0 2px rgba(115, 115, 115, 0.5);
	border: 0.5px solid rgb(171, 171, 171);
}

.btn-delete {
	box-shadow: 0 0 2px rgba(115, 115, 115, 0.5);
	border: 0.5px solid rgb(171, 171, 171);
}

.button1 {
	width: var(--commonbuttonwidth);
	height: var(--commmonbuttonheight);
	margin-left: calc(var(--commonHorizontalMargin) / 2);
	margin-right: calc(var(--commonHorizontalMargin) / 2);
	border: 1px solid black;
	background-image: var(--highlight2);
	/* background-image: linear-gradient(45deg, #f06, #f80); */
	color: var(--blackOrWhite2);
	font-size: 16px;
	text-transform: uppercase;
	box-shadow: 0 4px 8px rgba(109, 109, 109, 0.932);
	transition: box-shadow 0.3s;
}
.button1:hover {
	background-image: linear-gradient(45deg, var(--highlight2), #f06);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Button 1 Funky style */
/* .btn {
	width: var(--commonbuttonwidth);
	height: var(--commmonbuttonheight);
	margin-left: calc(var(--commonHorizontalMargin) / 2);
	margin-right: calc(var(--commonHorizontalMargin) / 2);
	background-image: linear-gradient(45deg, #f06, #f80);
	border: none;
	border-radius: 25px;
	color: white;
	cursor: pointer;
	font-size: 18px;
	padding: 12px 24px;
	text-transform: uppercase;
	transition: 0.3s;
}

.btn:hover {
	background-image: linear-gradient(45deg, #f80, #f06);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
} */

/* Button 2 Funky style */
/* .btn {
	width: var(--commonbuttonwidth);
	height: var(--commmonbuttonheight);
	margin-left: calc(var(--commonHorizontalMargin) / 2);
	margin-right: calc(var(--commonHorizontalMargin) / 2);
	background-color: #8e44ad;
	border: none;
	border-radius: 8px;
	color: white;
	cursor: pointer;
	font-size: 18px;
	padding: 12px 24px;
	position: relative;
	text-transform: uppercase;
	transition: 0.3s;
}

.btn:active {
	top: 4px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */

/* Button 3 Funky style */
/* .btn {
	width: var(--commonbuttonwidth);
	height: var(--commmonbuttonheight);
	margin-left: calc(var(--commonHorizontalMargin) / 2);
	margin-right: calc(var(--commonHorizontalMargin) / 2);
	background-color: rgba(0, 0, 0, 0.446);
	border: none;
	cursor: pointer;
	font-size: 18px;
	overflow: hidden;
	padding: 12px 24px;
	position: relative;
	text-transform: uppercase;
	transition: 0.3s;
}

.btn:before {
	background-color: #2c3e50;
	content: "";
	height: 100%;
	left: -100%;
	position: absolute;
	top: 0;
	transform: skewX(-45deg);
	transition: 0.3s;
	width: 100%;
	z-index: -1;
}

.btn:hover:before {
	left: 100%;
}

.btn span {
	color: #2c3e50;
	position: relative;
	z-index: 1;
}

.btn:hover span {
	color: white;
} */
