.testing-options-section {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	/* background-color: yellow; */
}

.testing-options-section .form-check {
	margin: 0em 1em 0em 1em;
}
