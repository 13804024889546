@keyframes blink {
	0%,
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.record-audio-buttons {
	grid-area: s3;
	margin-bottom: var(--commonVerticalMargin);
	display: flex;
	justify-content: center;
	align-items: center;
}

.record-audio-player {
	margin-bottom: var(--commonVerticalMargin);
}

.test-yourself-section .controls-section .record-audio-section .btn {
	margin-left: calc(var(--commonHorizontalMargin) * 2);
	margin-right: calc(var(--commonHorizontalMargin) * 2);
}

.button-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.circle {
	width: 15px;
	height: 15px;
	background-color: red;
	border-radius: 50%;
	margin-right: 5px;
}

.blink {
	animation: blink 1s linear infinite;
}

/* audio {
	width: calc(
		(var(--commonbuttonwidth) * 2) + (var(--commonHorizontalMargin) * 1)
	);
	height: calc(var(--commmonbuttonheight) + 0px);
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
	border: 1px solid #ccc;
	border-radius: 20px;
} */
