.home {
	flex-grow: 1;
	display: flex;
	padding: 4vh 4vw;
	justify-content: center;
	flex-flow: column wrap;
	border-radius: 0.5rem;
}

.home h1 {
	font-size: 4em;
}

.home p {
	padding: 0px 2vw;
}

.home .btn {
	background-color: var(--highlight2);
	color: white;
	margin-top: 3cm;
	align-self: center;
	height: 3.5em;
	width: 10rem;
	border: 1px solid black;
	transition: all 0.2s ease-in-out;
}

.home button:hover {
	transform: scale(1.05);
}
