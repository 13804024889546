.disabled-audio {
	min-width: 10em;
	/* width: var(--commonbuttonwidth); */
	width: calc((var(--commonbuttonwidth) * 2) + var(--commonHorizontalMargin));
	height: var(--commmonbuttonheight);
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
	border: 1px solid #ccc;
	border-radius: 20px;
}

audio {
	min-width: 10em;
	width: calc((var(--commonbuttonwidth) * 2) + var(--commonHorizontalMargin));
	height: calc(var(--commmonbuttonheight) + 0px);
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
	border: 1px solid #ccc;
	border-radius: 20px;
}

@media (min-width: 769px) and (max-width: 992px) {
	.disabled-audio {
		width: calc((30vw * 2) + var(--commonHorizontalMargin));
	}

	audio {
		width: calc((30vw * 2) + var(--commonHorizontalMargin));
	}
}

@media (max-width: 768px) {
	.disabled-audio {
		width: calc((40vw * 2) + var(--commonHorizontalMargin));
	}

	audio {
		width: calc((40vw * 2) + var(--commonHorizontalMargin));
	}
}
